import Subscriber from './subscriber';
import prods from "../data.json";

class dataService extends Subscriber {
    constructor() {
     if(!dataService.instance) {
        super(); 
        this._data = [];
        this._filteredResults = [];
        this._categories = [];
        dataService.instance = this;
     }
     return dataService.instance;
    }

    readInventory(callBack) {
     //console.log('readInventory', this._data);
        if( this._data.length === 0) {
          this._data.push(...prods);
          callBack(prods);
        } else {
         callBack(this._data);
        }
    }

    getUnique(array = [this._data], key) {
      this._categories.push(...this.uniq_fast(array, key));
    }

    uniq_fast(a, key) {
      var seen = {};
      var out = [];
      var len = a.length;
      var j = 0;
      for(var i = 0; i < len; i++) {
          var item = a[i][key];
          if(seen[item] !== 1) {
                seen[item] = 1;
                out[j++] = item;
          }
      }
      return out;
  }

    orderBy(props = [], orders = [], arr = [...this._data]) {
        const key = `orderBy${props.toString() + orders.toString()}`;
        if(!this._filteredResults[key]) {
            this._filteredResults[key] = [...arr].sort((a, b) =>
                props.reduce((acc, prop, i) => {
                if (acc === 0) {
                    const [p1, p2] = orders && orders[i] === 'desc' ? [b[prop], a[prop]] : [a[prop], b[prop]];
                    acc = p1 > p2 ? 1 : p1 < p2 ? -1 : 0;
                }
                return acc;
                }, 0)
            );
        }
        this.notify(this._filteredResults[key]);
        return key;
    }

    //filterType ['value', 'numeric','text']
    filterBy (prop = '', value = '', filterType = 'value', arr = this._data) { 
     // console.log('filterBy', prop, value, filterType);
      if(value === 'all') {
        return arr;
      }       
        const filteredArr = 
        arr.filter(
          item => {
            if(filterType === 'numeric') {
              return Number(item[prop]) >= Number(value[0]) && Number(item[prop]) <= Number(value[1]);
            } else if (filterType === 'value') {
              return (Array.isArray(value) ? value.includes(item[prop]) : item[prop] === value); 
            } else if(filterType === 'text'){
              return item[prop].toLowerCase().includes(value);
            }
            return false;
          }
        );
        return filteredArr;
    }
  }
  
  const instance = new dataService();
  Object.freeze(instance);
  
  export default instance;