import React from 'react'
import fileService from '../service/fileService';

const FileDownload = ({btnText='',  name=''}) => {
   return(
    <div style={{ maxWidth: '28rem' }}>
            {fileService.getLink({fileName: name, btnText: btnText })}
  </div>
  )
}
export default FileDownload;