
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

class fileService  {
    constructor() {
        if(!fileService.instance) {
            fileService.instance = this;
         }
         return fileService.instance;
    }
   

  getLink({ fileName, btnText="" }) {
    return(<StaticQuery
      query={graphql`
      query {
        allFile(filter: {ext: {eq: ".pdf"}}) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }       
      `}
      render = { data => {
      const file = data.allFile.edges.find(
          edge =>{ 
               return  edge.node.name === fileName}
      );

      if (!file) {
        return null;
      }
      //console.log(file);   
      return <a href={file.node.publicURL}>{btnText}</a>;
      }}
    />);
  }


}

const instance = new fileService();
Object.freeze(instance);

export default instance;