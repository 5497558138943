export default class Subscriber {
    constructor() {
        this.subscriptors = [];
    }

    subscribe(subscriptor) {
        this.subscriptors.push(subscriptor);
    }
    
    unsubscribe(subscriptor) {
        this.subscriptors = this.subscriptors.filter( item => item !== subscriptor );
    }
    
    notify(event) {
        //console.log('notify: ', this, event);
        this.subscriptors.forEach( item => {
            item.call(this, event); 
        });
    }  

}
