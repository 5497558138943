import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import FileDownload from '../components/fileDownload';
import dataService from '../service/dataService';


import { Tabs, useTabState, usePanelState  } from '@bumaga/tabs'; 

const Tab = ({ children }) => {
  const { onClick, isActive } = useTabState();
  return <a className={!isActive ? "tab" :"tab-active"} onClick={onClick}>{children}</a>;
};

const Panel = ({ children }) => {
  const isActive = usePanelState();

  return isActive ? <p>{children}</p> : null;
};

const Resources = props => {
  const [hidden, setHidden] = useState(true);
  const[files, setFiles] = useState([]);
  const [online, isOnline] = useState(false);

  useEffect(() => {
      function handleStatusChange(status) {
        setFiles(status);
          isOnline(!online);
      }
      dataService.subscribe(setFiles);
      dataService.readInventory(handleStatusChange);
      
 //console.log("files read", files);
    }, []);

//console.log("files", files);
  return (
  <Layout>
  <Helmet
  htmlAttributes={{ lang: 'en' }}
  title="Resources - Bulls i Marketing"
  meta={[
    { name: 'description', content: '' },
    { name: 'keywords', content: ' ' },
  ]}
></Helmet>
<StaticQuery
    query={graphql`
      query SiteResourceQuery {
        site {
          siteMetadata {
            resources {
              link
              description
            }
          }
        }
      }
    `}

    render={data => (
      <React.Fragment>

            <div id="main" className="wrapper style4">
      <div className="container">
      <article>
        <header className="major">
          <h2>Resources</h2>
        </header>

  
        <Tabs>
        <div>
          <Tab><span >Videos</span></Tab>
          <Tab><span >Files</span></Tab>
        </div>

    <Panel>
      <section className="tab-panel">
        { hidden && <ul>
          {
              data.site.siteMetadata.resources.map( r => <li><a href={r.link}>{r.description}</a></li>)
          }
          </ul>
          }
          <hr />
        </section>
    </Panel>

    <Panel>
      
    <section className="tab-panel">
          <h3>PDFs</h3>
        <div id="products">
              {files.map((p, index) => 
                <FileDownload key ={`${p.fileName}-${index}`} name={p.fileName} btnText={p.fileName} >
                  </FileDownload>)
               }
          </div>
          <hr />

        </section>
    </Panel>

  </Tabs>
 

        </article>
       </div>
    </div>


      </React.Fragment>
    )}
  />
    
    </Layout>
)}

export default Resources
